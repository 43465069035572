@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
.dark-mode .modal {
    background-color: #1e1e1e;
    color: #ffffff;
  }
  
  .dark-mode .popup {
    background-color: #1e1e1e;
    color: #ffffff;
  }