.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1000px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

button.close {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-size: 24px;
}
